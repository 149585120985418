<template>
  <v-form ref="UserForm" @submit.prevent="handleSave">
    <v-container class="form__container" fluid>
      <v-row>
        <v-col class="text-h5">
          {{ $trans("notification_templates_list_for_client") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-container>
            <v-row>
              <v-col>
                {{ $trans("email_notifications") }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="email_notification_booking_created"
                  :label="$trans('booking_created')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="email_notification_booking_updated"
                  :label="$trans('booking_updated')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                  v-model="email_notification_booking_deleted"
                  :label="$trans('booking_deleted')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                  v-model="email_notification_booking_reminder"
                  :label="$trans('booking_reminder')"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="6">
          <v-container>
            <v-row>
              <v-col>
                {{ $trans("sms_notifications") }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="sms_notification_booking_created"
                  :label="$trans('booking_created')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="sms_notification_booking_updated"
                  :label="$trans('booking_updated')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                  v-model="sms_notification_booking_deleted"
                  :label="$trans('booking_deleted')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                  v-model="sms_notification_booking_reminder"
                  :label="$trans('booking_reminder')"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="primary"
            depressed
            type="submit"
          >
            {{ $trans("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";

export default {
  name: "UserPreferences",
  data() {
    return {
      isLoading: false,
      invalidForm: false,
      email_notification_booking_created: false,
      email_notification_booking_updated: false,
      email_notification_booking_deleted: false,
      email_notification_booking_reminder: false,
      sms_notification_booking_created: false,
      sms_notification_booking_updated: false,
      sms_notification_booking_deleted: false,
      sms_notification_booking_reminder: false,
    };
  },

  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
    }),
  },
  watch: {
    selectedUser(val) {
      if (val !== null) {
        this.fillForm();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.fillForm();
    });
  },
  methods: {
    ...mapActions({
      updatePreferences: "user/updatePreferences",
    }),
    fillForm() {
      if (this.selectedUser && this.selectedUser.preferences) {
        this.email_notification_booking_created =
          this.selectedUser.preferences.email_notification_booking_created;
        this.email_notification_booking_updated =
          this.selectedUser.preferences.email_notification_booking_updated;
        this.email_notification_booking_deleted =
          this.selectedUser.preferences.email_notification_booking_deleted;
        this.email_notification_booking_reminder =
          this.selectedUser.preferences.email_notification_booking_reminder;
        this.sms_notification_booking_created =
          this.selectedUser.preferences.sms_notification_booking_created;
        this.sms_notification_booking_updated =
          this.selectedUser.preferences.sms_notification_booking_updated;
        this.sms_notification_booking_deleted =
          this.selectedUser.preferences.sms_notification_booking_deleted;
        this.sms_notification_booking_reminder =
          this.selectedUser.preferences.sms_notification_booking_reminder;
      }
    },
    handleSave() {
      if (this.$refs.UserForm.validate()) {
        this.isLoading = true;

        this.updatePreferences({
          id: this.selectedUser.id,
          email_notification_booking_created:
            this.email_notification_booking_created,
          email_notification_booking_updated:
            this.email_notification_booking_updated,
          email_notification_booking_deleted:
            this.email_notification_booking_deleted,
          email_notification_booking_reminder:
            this.email_notification_booking_reminder,
          sms_notification_booking_created:
            this.sms_notification_booking_created,
          sms_notification_booking_updated:
            this.sms_notification_booking_updated,
          sms_notification_booking_deleted:
            this.sms_notification_booking_deleted,
          sms_notification_booking_reminder:
            this.sms_notification_booking_reminder,
        })
          .then(() => {
            successNotification("update_success");
          })
          .catch((error) => {
            errorNotification(null, error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
  },
};
</script>
